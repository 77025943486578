.app {
    .container {
        .calender {
            #container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                .react-calendar {
                    width: 70vw;
                    height: 70vh;
                    font-size: 20px;
                    border: none;

                    .react-calendar__navigation {
                        font-size: 20px;
                    }

                    .react-calendar__month-view__days__day {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;


                        #calender-tile {
                            font-size: 14px;
                            margin: 5px;
                        }
                    }

                    .react-calendar__tile {
                        height: 100px;
                        border: 1px solid grey;

                    }
                }
            }
        }
    }
}