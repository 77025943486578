* {
    margin: 0;
    padding: 0;
    font-family: "Century Gothic", CenturyGothic, Geneva, AppleGothic, sans-serif;
    overflow: hidden;
}

.app {
    display: flex;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    background-color: white;

    .container {

        .header {
            width: 100vw;
            height: 87px;
            background-color: lightblue;
            display: flex;


            #container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100vw;

                #header-img {
                    width: 190px;
                    height: 50px;
                    margin-left: 60px;
                }

                #header-clock {
                    order: 2;
                    font-size: 25px;
                }

                #header-login {
                    order: 3;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    input {
                        margin: 5px;
                        height: 40px;
                        width: 180px;
                        padding-left: 10px;
                        border: none;
                        border-radius: 5px;
                    }

                    button {
                        margin: 5px 18px 0px 5px;
                        width: 100px;
                        height: 41px;
                        border: none;
                        border-radius: 5px;
                        font-size: 18px;
                    }

                    #header-user {
                        margin-right: 15px;
                        font-size: 18px;
                    }
                }

                button:hover {
                    background-color: grey;
                    color: orange;
                }
            }
        }

        .logoff {
            position: absolute;
            top: 88px;
            left: 360px;
            width: 85vw;
            height: 95vh;
            background-color: white;
            overflow: scroll;
        }

        .logon {
            position: absolute;
            top: 88px;
            left: 360px;
            width: 85vw;
            height: 95vh;
            background-color: white;
            overflow: scroll;
        }

        .config {
            position: absolute;
            top: 88px;
            left: 360px;
            width: 85vw;
            height: 95vh;
            background-color: white;
            overflow: scroll;
        }

        .calender {
            position: absolute;
            top: 88px;
            left: 360px;
            width: 85vw;
            height: 95vh;
            background-color: white;
            overflow: scroll;
        }
    }
}