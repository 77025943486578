.app {
    .container {
        .navbar {
            width: 360px;
            height: 95vh;
            background-color: lightblue;

            #container {
                display: flex;
                flex-direction: column;

                #navbar-buttons,
                #navbar-config {
                    margin-top: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    button {
                        margin: 10px 0px 10px 0px;
                        width: 350px;
                        height: 50px;
                        border: none;
                        background-color: lightblue;
                        font-size: 20px;
                        text-align: left;
                        padding-left: 60px;
                    }

                    button:hover {
                        color: orange;
                        border-top: 1px solid black;
                        border-bottom: 1px solid black;
                        border-left: none;
                        border-right: none;
                        background-color: grey;
                    }
                }

                #navbar-config {
                    position: absolute;
                    top: 90vh;
                    left: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}